const Button = (props) =>{
    const btndivstyle = {
        borderRadius:10,
        width:'100%',
        padding:'5px 10px',
        border:'none',
        background:props.color?'#fff':'#380351',
        cursor:'pointer',
        display:'flex',
        alignItem:'center',
        height:'100%'
    }
    const btnstyle={
        width:'70%',
        backgroundColor:'transparent',
        border:'none',
        fontFamily:'MontserratBold',
        fontWeight:600,
        fontSize:15,
        color:props.color?'#380351':'#fff',
        textAlign:'left'
    }
    return(
        <>
        <div style={btndivstyle} className='btndivstyle'>
            <div style={{display:'flex',justifyContent:'flex-end',marginRight:10}}>
            <img src={props.icon} style={{width:'30px'}}/>
            </div>
        <button  style={btnstyle}>{props.name}</button>
        </div>
        </>
    )
}
export default Button;