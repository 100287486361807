import './App.css';
import Banner from './component/banner';
import Footer from './component/footer';
import Navbar from './component/navbar';
import Owner_Section from './component/owner_section';

function App() {
  return (
    <>
    <div className="App">
     <Navbar/>
     <Banner/>
    </div>
    <Owner_Section/>
    <Footer/>
    </>
  );
}

export default App;
