import linkedin from '../assests/icons/FooterIcon-Linkedin.svg'
import twitter from '../assests/icons/Icon_-_Twitter_White.svg'
import Logo from "../assests/icons/HPLogo-NavBar.svg";
import rights from "../assests/icons/icon-Copyright.svg";
import Badge from '../assests/icons/Badge.svg'
import fb from "../assests/icons/FooterIcon-Facebook.svg";
import Insta from "../assests/icons/FooterIcon-Instagram.svg";
const Footer = () =>{
    return(
        <>
        
        <div className="footer">
        <div className="container">
          <div className="row">
            <div id="left" className="col-sm-12 col-md-4 col-lg-4">
            <a href='https://hashpotato.io' target={'_blank'}>
              <div className="logo_div" style={{ marginBottom: "1rem" }}>
                <img className="img-fluid" src={Logo} width={220} height={50} />
              </div>
              </a>
              <p className="p">
                HashPotato is a digital design and development company that
                provides creative solutions for businesses all across the globe.
              </p>
            <a style={{textDecoration:'none'}} href='https://hashpotato.io' target={'_blank'}>
              <div style={{ marginTop:'35px' }} className="copyright-box">
                <img src={rights} style={{ paddingRight: "5px" }} />
                <p id="p"> HashPotato 2022  All Rights Reserved</p>
              </div>
              </a>
              {/* <p id="p" style={{ paddingLeft: "0" }}>
                All Rights Reserved
              </p> */}
            </div>
            <div id="right" className="col-sm-12 col-md-4 col-lg-4">
              <p id="p">Reach out to us:</p>
            <a href="mailto: business@hashpotato.io" target="_blank" style={{color:'#fff',textDecoration:'none'}}>
                <p id="p"> business@hashpotato.io</p>
                </a>
                <p id="p"><a href="tel:+92 324 1270169"  target="_blank" style={{color:'#fff',textDecoration:'none'}}>
              +92 324 1270169
                </a></p>
              <p className="p">
              Plot# A-197, Block 13D-1, Gulshan-e-Iqbal,
                Karachi, Pakistan
              </p>
             
               
              <div className="sociallinks">
              <a href="https://pk.linkedin.com/company/hash-potato-io"
                  target="_blank"
                >
                  <img src={linkedin} width={25} height={25} />
                </a>
                <a
                  href="https://www.facebook.com/hashpotato.io"
                  target="_blank"
                >
                  <img src={fb} width={25} height={25} />
                </a>
                <a
                  href="https://www.instagram.com/hashpotato.io/"
                  target="_blank"
                >
                  <img src={Insta} width={25} height={25} />
                </a>
                <a href="https://twitter.com/HashPotato_io"
                  target="_blank"
                  >
                {/* <AiFillTwitterSquare style={{fontSize: '30px',
    color: '#fff', marginTop:-'1px'}}/> */}
                  <img src={twitter} width={25} height={25} />
                </a>
                
              </div>
            </div>
            <div id="right" className="col-sm-12 col-md-4 col-lg-4 footer-img-div">
            <a href="https://www.designrush.com/agency/software-development/blockchain" style={{cursor:'pointer'}} target="_blank">
           <img src={Badge} style={{width: '140px',height: '210px'}}/>
           </a>

           <a style={{textDecoration:'none'}} href='https://hashpotato.io' target={'_blank'}>
           <div style={{ marginTop:'30px' }} className="copyright-box1">
          
            <img src={rights} style={{ paddingRight: "5px" }} />
                <p id="p" style={{marginTop:'10px'}}> Hash Potato 2022  All Rights Reserved</p>            
            
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    {/* </> */}
        </>
    )
}
export default Footer;