import PurpleSword1 from "../assests/images/PurpleSword1.svg";
import Button from "./button/button";
import opensea from "../assests/icons/opensea.svg";
const Banner = () => {
  return (
    <>
      <div className="banner-container">
        <div className="banner-div">
          <div className="banner-img">
            <img src={PurpleSword1} style={{ width: "100%" }} />
          </div>
          <div className="banner-content">
            <div>
              <h2>Gladiator’s Drive | Flood Relief Campaign</h2>
              {/* <div> */}
                {/* <div> */}
                  <p>
                    Gladiators Drive is a collaborative NFT campaign between
                    Quetta Gladiators and HashPotato.
                  </p>
                {/* </div> */}
                <p>
                  The NFT <span>"Purple Sword"</span> is based on a physical and
                  exclusive cricket bat signed by 16 Gladiators which is being
                  auctioned on OpenSea.
                </p>
              {/* </div> */}
              {/* <div> */}
                <p>
                  The current holder will be able to redeem exclusive Quetta
                  Gladiators Merchandise every PSL season. Whereas the first
                  holder will be eligible for the many other utilities along
                  with the signed physical cricket bat.
                </p>
              {/* </div> */}
              {/* <div> */}
                <p>
                  Amount generated by the Purple Sword will be donated to the
                  flash flood victims in Pakistan.
                </p>
              {/* </div> */}
            </div>
            <div className="banner-btn">
                <a style={{textDecoration:'none'}} href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/100350015592793757832849444667981238512964062255793644012400458396905664151553" target={'_blank'}>
              <Button name="View on Opensea" icon={opensea} color={true} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
