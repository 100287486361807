import bat1 from '../assests/images/bat1.svg'
import bat2 from '../assests/images/bat2.svg'
import mintslot from '../assests/images/mints-slot.svg'
import Jersey from '../assests/images/Custom-Jersey.svg'
import tickets from '../assests/images/tickets.svg'
import Social from '../assests/images/social.svg'
import Button from './button/button'
import discord from '../assests/icons/discord.svg'
const Owner_Section = () =>{
    return(
        <>
        <div className='owner-container'>
            <div className='owner-div'>
                <div>
                <h2>First Owner</h2>
                <p>The current holder will get the following utilities</p>
                </div>
                <div className="owner-section-img">
                    <div>
                    <img src={bat1}/>
                    </div>
                    <div>
                    <img src={bat2}/>
                    </div>
                </div>
            </div>

            <div className='owner-div'>
                <div>
                <h2>Other Utilities</h2>
                <p>The current holder will get the following utilities</p>
                </div>
                <div className="owner-section-img2">
                <div>
                    <img src={mintslot}/>
                    <h3>3 Free Mint <br/> Slots</h3>
                    </div>
                    <div>
                    <img src={Jersey}/>
                    <h3>Custom Quetta <br/> Gladiators Jersey</h3>
                    </div>
                    <div>
                    <img src={tickets}/>
                    <h3>1 VIP Ticket Every <br/> PSL Season</h3>
                    </div>
                    <div>
                    <img src={Social}/>
                    <h3>Special Mention <br/> on Social Media</h3>
                    </div>

                </div>
            </div>

            <div className='owner-div'>
            <div style={{display:'flex',justifyContent:'center',alignItems:"center",flexDirection:'column'}}>
                <div style={{marginBottom:'10px',height:'55px'}} className='discord-div'>
                <a style={{textDecoration:'none'}} href="https://discord.gg/h2XwG2ey" target={'_blank'}>
                <Button icon={discord} color={false} name='Join us on Discord'/>
                </a>
                </div>
                <p className='discord-para'>Join our community for more exciting updates.</p>
            </div>
            </div>
        </div>
        
        </>
    )
}
export default Owner_Section;