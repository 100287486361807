import Logo from '../assests/icons/HPLogo-NavBar.svg'
import Button from './button/button'
import tick from '../assests/icons/tick.svg'
import tickgray from '../assests/icons/tickgray.svg'
const Navbar = () =>{
    return(
        <>
        <div className='navbar-container'>
            <div className='navbar-div'>
                <a href='https://hashpotato.io' target={'_blank'}>
                <img src={Logo} />
                </a>
                <div className='navbar-section2'>
                    {/* <div style={{width:'49%',height:50}} >
                    <Button name='View Form' icon={tickgray} color={false}/>
                    </div>
                    <div style={{width:'49%',height:50}}>
                    <Button name='Verify your purchase' icon={tick} color={true}/>
                    </div> */}
                    </div>

                    {/* <div className='navbar-section3'>
                        <img src={tick} />
                        <img src={tickgray} style={{marginLeft:10}}/>

                    </div> */}
            </div>
        </div>
        </>
    )
}
export default Navbar;